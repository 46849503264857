import {
  fetch,
  logMessage,
  logError,
  getEventFilterDate,
  getOperationalDate,
  getPacificDateString,
} from '../../helpers';

const transformMenu = (menuResp: MenuResp, storeId: string): MenuType => {
  const categories = menuResp.categories.map(
    ({ id, name, description, products, slug }) => ({
      id,
      name,
      description,
      products: products.map((cp) => cp.product),
      slug,
    })
  );

  const menu: MenuType = {
    id: menuResp.id,
    name: menuResp.name,
    storeId,
    categories,
    productLibrary: {},
    version: menuResp.version,
  };

  const productCategories: { [id: string]: string } = {};
  categories.forEach((c) =>
    c.products.forEach((pId) => {
      productCategories[pId] = c.name;
    })
  );

  const productLibrary: LibraryType = {};

  const isGeneric = storeId === 'generic';

  const getChoices = (
    modifier: ModifierResp,
    menuProduct?: CategoryProductResp
  ) => {
    const menuModifier = menuProduct?.modifiers.find(
      (mm) => mm.id === modifier.id
    );
    if (!menuModifier)
      logError(
        `Corresponding modifier for product not found on menu - ${modifier.id} - ${menu.id}`
      );

    return modifier.choices.map((c: ChoiceResp) => {
      const menuChoice = menuModifier?.choices.find(
        (mc: { id: string }) => mc.id === c.id
      );
      if (!menuChoice)
        logError(
          `Corresponding choice for product modifier not found on menu - ${c.id} - ${menu.id}`
        );

      return {
        id: c.id,
        name: c.name,
        default: c.default,
        inStock: isGeneric ? true : c.in_stock,
        price: menuChoice?.price || 0,
      };
    });
  };

  const menuProducts: CategoryProductResp[] = [];
  menuResp.categories.forEach((c) =>
    c.products.forEach((p) => menuProducts.push(p))
  );

  menuResp.products.forEach((p) => {
    const menuProduct = menuProducts.find((mp) => mp.product === p.id);
    if (!menuProduct)
      logError(
        `Corresponding pricing information for product not found on menu - ${p.id} - ${menu.id}`
      );

    const product: ProductType = {
      id: p.id,
      name: p.name,
      slug: p.slug,
      description: p.description,
      price: menuProduct?.price || 0,
      priceMax: menuProduct?.price_max || 0,
      inStock: isGeneric ? true : p.in_stock,
      category: productCategories[p.id],
      modifiers: p.modifiers.map((m) => ({
        id: m.id,
        name: m.name,
        hidden: m.hidden,
        minChoices: m.min_choices,
        maxChoices: m.max_choices,
        minQuantity: m.min_quantity,
        maxQuantity: m.max_quantity,
        choices: getChoices(m, menuProduct),
      })),
    };

    if (isGeneric) product.genericItem = true;
    productLibrary[product.id] = product;
  });

  menu.productLibrary = productLibrary;

  return menu;
};

export const fetchMenu = async (
  storeId: string
): Promise<{
  menu?: MenuType;
  error?: string;
}> => {
  const url = `/customer/stores/${storeId}/menu/`;
  const res = await fetch(url);

  if (!res?.categories || !res?.products) return { error: 'no menu returned' };
  return { menu: transformMenu(res, storeId) };
};

export const fetchGenericMenu = async () => {
  const genericUrl = '/customer/menus/generic/';
  const genericRes = await fetch(genericUrl);
  if (!genericRes.menu || !genericRes.products)
    return { error: 'no valid generic menu response returned' };

  const { menu, products } = genericRes;

  const menuResp = {
    id: menu.id,
    name: menu.name,
    categories: menu.categories,
    products,
    version: menu.version,
  };

  const genericMenu = transformMenu(menuResp, 'generic');
  return { genericMenu };
};

// We will no longer be reading store hours directly from store, instead we will get from todays event (if it exists)
// const oldgetStoreTimes = (
//   open_at: string | null,
//   close_at: string | null,
//   preorder_at: string | null
// ) => {
//   let timesValid = true;

//   [open_at, close_at, preorder_at].forEach((time) => {
//     if (!time) return;
//     const date = new Date(time);
//     const fourHours = 1000 * 60 * 60 * 4;
//     const unix = date.getTime() - fourHours;
//     const shiftedDate = new Date(unix);

//     const now = new Date();
//     const unixNow = now.getTime() - fourHours;
//     const shiftedNow = new Date(unixNow);
//     if (shiftedDate.getDate() !== shiftedNow.getDate()) timesValid = false;
//   });

//   if (!timesValid) {
//     logMessage(
//       `Store hours do not match todays date - ${
//         open_at || close_at || preorder_at
//       }`
//     );
//     return { openAt: '', closeAt: '', preorderAt: '' };
//   }

//   return {
//     openAt: open_at || '',
//     closeAt: close_at || '',
//     preorderAt: preorder_at || '',
//   };
// };

const getStoreTimes = (storeEvents: StoreEventResp[]) => {
  const today = getOperationalDate();

  const todaysEvent = storeEvents?.find((event) => {
    if (!event.open_at) return false;
    const eventDate = new Date(event.open_at);
    getOperationalDate(eventDate);

    return (
      getPacificDateString(today.toISOString()) ===
      getPacificDateString(eventDate.toISOString())
    );
  });

  const openAt = todaysEvent?.open_at || '';
  const closeAt = todaysEvent?.close_at || '';
  const preorderAt = todaysEvent?.preorder_at || '';

  return { openAt, closeAt, preorderAt };
};

const transformStoreEvent = (storeEvent: StoreEventResp) => ({
  id: storeEvent.id,
  store: storeEvent.store || 'no store present',
  address: storeEvent.address,
  name: storeEvent.name,
  preorderAt: storeEvent.preorder_at || '',
  openAt: storeEvent.open_at || '',
  closeAt: storeEvent.close_at || '',
});

const readStore = (storeResp: StoreResp): StoreType | null => {
  if (!storeResp) return null;

  const {
    id,
    address,
    name,
    menu,
    customer_ordering_enabled,
    visible,
    mobile,
    estimated_wait_time_minutes,
    latitude,
    longitude,
    display_customer_referral,
    defer_payments,
    store_events,
  } = storeResp;

  return {
    id,
    address,
    name,
    open: customer_ordering_enabled,
    showReferrals: display_customer_referral,
    menuId: menu,
    visible,
    mobile,
    waitTime: estimated_wait_time_minutes,
    latitude,
    longitude,
    ...getStoreTimes(store_events),
    deferPayment: defer_payments,
    events: store_events?.map(transformStoreEvent) || [],
  };
};

const eventWithinWeek = (event: StoreEventResp) => {
  if (!event.open_at || !event.close_at) return false;

  const eventDate = new Date(event.open_at).getTime();
  const now = Date.now();
  const oneWeek = 1000 * 60 * 60 * 24 * 7;
  if (eventDate - now > oneWeek) return false;
  return true;
};

export const fetchStores = async () => {
  const eventFilterDate = getEventFilterDate();

  const url = `/customer/stores/?include[]=store_events.*&filter{store_events|open_at.gte}=${eventFilterDate}`;
  const res = await fetch(url);
  if (!res?.stores) return { error: 'no stores returned' };

  const respEvents = res.events || [];
  const storesWithEvents = res.stores.map((s: RawStoreResp) => {
    const storeEvents = s.store_events
      .map((se) => {
        const event = respEvents.find((e: StoreEventResp) => e.id === se);
        if (!event)
          logMessage(`Store event not found from platform response - ${se}`);
        if (event && event.public && eventWithinWeek(event) && !s.mobile) {
          return event;
        }
      })
      .filter(Boolean);
    return {
      ...s,
      store_events: storeEvents,
    };
  });

  const stores = storesWithEvents.map(readStore);
  return { stores };
};

type RawStoreEventType = StoreEventType & {
  store: string;
};

export const fetchStoreEvents = async () => {
  const url = '/customer/events/?include[]=store.*';
  const res = await fetch(url);
  if (!res?.events) return { error: 'no events returned' };
  const events = res.events
    .filter(eventWithinWeek)
    .map(transformStoreEvent)
    .map((e: RawStoreEventType) => {
      const store = res.stores?.find((s: StoreEventResp) => s.id === e.store);
      return { ...e, store: readStore(store) || e.store };
    });
  return { events };
};

export const fetchStore = async (storeId: string) => {
  const url = `/customer/stores/${storeId}/?include[]=store_events.*`;
  const res = await fetch(url);
  if (!res?.store) return { error: 'no stores returned' };
  const store = readStore(res.store);
  return { store };
};
